document.addEventListener('DOMContentLoaded', () => {
	const rentByDay = document.getElementById('rent_by_day');
	const noDelivery = document.getElementById('no_delivery');
	const variationsButton = document.querySelector('.variations_button');
	const enquiryButton = document.querySelector('.enquiry-button');
	const enquiryAnfragen = document.querySelector('.enquiry-anfragen');





	document.addEventListener('click', function (event) {
		var isMieten = event.target.closest('[data-attr_value="mieten"]');
		var isKaufen = event.target.closest('[data-attr_value="kaufen"]');

		if (isMieten || isKaufen) {
			onSwatchClick(event);
		}
	});

	function onSwatchClick(e) {
		const swatch = e.target.closest('.swatchly-swatch');

		if (!swatch.classList.contains('swatchly-selected')) {
			e.preventDefault();
			e.stopPropagation();
			return;
		}

		const attrValue = swatch.getAttribute('data-attr_value');
		const isKaufen = attrValue === 'kaufen';
		const isMieten = attrValue === 'mieten';

		rentByDay.style.display = isKaufen ? 'none' : 'block';
		noDelivery.style.display = isKaufen ? 'block' : 'none';
		variationsButton.style.display = isKaufen ? 'flex' : 'none';
		if (enquiryButton) {
			enquiryButton.style.display = isKaufen ? 'none' : 'flex';
		} else if (enquiryAnfragen) {
			enquiryAnfragen.style.display = isKaufen ? 'none' : 'flex';
		}

	}

	var modalOverlay = document.getElementById('modal-overlay');
	var modalForm = document.querySelector('.modal-form');
	var closeModalBtn = document.querySelector('.frm_close');
	var openModalBtn = document.querySelector('.enquiry-button');

	if (document.querySelectorAll('.validation_message').length > 0) {
		modalOverlay.style.display = 'flex';
	}
	if (openModalBtn) {
		openModalBtn.addEventListener('click', function () {
			modalOverlay.style.display = 'flex';
			document.body.style.overflow = 'hidden';
		});
	}


	closeModalBtn.addEventListener('click', closeModal);

	modalOverlay.addEventListener('click', function (event) {
		if (event.target === modalForm || modalForm.contains(event.target)) {
			return;
		}
		closeModal();
	});

	function closeModal() {
		modalOverlay.style.display = 'none';
		document.body.style.overflow = '';
	}
});

jQuery(document).ready(function ($) {
	if ($(window).width() <= 767) {
		$('.woocommerce-product-gallery').flexslider({
			controlNav: true,
			directionNav: false,
		});
	}



	// Use attribute selector to find the element regardless of its ID
	var $orderTypeSelect = $('select[name="attribute_pa_order-type"]');
	const $variations = $('.variations');
	var optionValues = [];

	$orderTypeSelect.find('option').each(function () {
		var value = $(this).val();
		if (value !== '') {
			optionValues.push(value);
		}
	});

	var $kaufenSwatch = $('div.swatchly-swatch[data-attr_label="kaufen"]');
	var $mietenSwatch = $('div.swatchly-swatch[data-attr_label="mieten"]');
	if ($kaufenSwatch.length > 0 && optionValues.length > 1 && !$kaufenSwatch.hasClass('swatchly-selected')) {
		$kaufenSwatch.click();
	}

	const urlParams = new URLSearchParams(window.location.search);
    const orderType = urlParams.get('attribute_pa_order-type'); 

	if (orderType === 'mieten') {
		$kaufenSwatch.removeClass('swatchly-selected');
		$mietenSwatch.click();
	} else if (optionValues.includes('kaufen')) {
		$('#rent_by_day').hide();
	}

	if (optionValues.length === 1 && optionValues.includes('kaufen')) {
		$('.checkout-custom-container').hide();
	}



    // Short description see more/see less logic
    const seeMoreButtons = document.querySelectorAll('.see-more-button');

    seeMoreButtons.forEach(button => {
        button.addEventListener('click', function () {
            // Find the corresponding hidden content by navigating the DOM
            const hiddenText = this.previousElementSibling.querySelector('.hidden-half');

            if (hiddenText) {
                if (hiddenText.style.display === 'none') {
                    hiddenText.style.display = 'inline';
                    this.innerText = 'Weniger anzeigen'; 
                } else {
                    hiddenText.style.display = 'none';
                    this.innerText = 'Mehr erfahren'; 
                }
            }
        });
    });
});